import { Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Link } from "react-router-dom";

import Page from '../Components/Layout/Page';

const PartnersPageMessages = defineMessages({
    pagesPartnersSectionSponsorsTitle: {
        id: 'PAGES_PARTNERS_SECTION_SPONSORS_TITLE',
        defaultMessage: 'Projekt podporují',
    },
    pagesMediaSectionPartnersTitle: {
        id: 'PAGES_MEDIA_SECTION_PARTNERS',
        defaultMessage: 'Mediální partneři',
    },
    pagesPartnersSectionCooperatorsTitle: {
        id: 'PAGES_PARTNERS_SECTION_DATA_COOPERATORS_TITLE',
        defaultMessage: 'Data poskytují',
    },
    pagesPartnersSectionOpendataTitle: {
        id: 'PAGES_PARTNERS_SECTION_OPENDATA_TITLE',
        defaultMessage: 'Využíváme veřejně dostupná a otevřená data',
    },
    pagesPartnersSectionThanksTitle: {
        id: 'PAGES_PARTNERS_SECTION_THANKS_TITLE',
        defaultMessage: 'Dále děkujeme',
    },

});

const PartnersPage: React.FC = () => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Page currentPage="partners">
            <Typography className={ classes.title } variant="h4" component="h1">
                { intl.formatMessage(PartnersPageMessages.pagesPartnersSectionSponsorsTitle) }
            </Typography>
            <br />
            <Grid container className={ classes.root } spacing={ 2 }>
                <Grid item xs={ 12 }>
                    <Grid container justify="center" spacing={ 5 }>
                        { [
                            { id: 'partner_philipMorris', url: 'https://www.pmi.com/markets/czech-republic/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/v1585917524/logo-philipMorris.png' },
                            // { id: 'partner_sodexo', url: 'https://www.sodexo.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/v1613548138/logo-sodexo.png' },
                            // { id: 'partner_lush', url: 'https://www.lush.com/cz/cs', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_pad,h_160,w_190/v1637777107/logo-lushFresh.png' },
                            { id: 'partner_garnier', url: 'https://www.garnier.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_pad,h_160,w_190/v1641391250/logo-garnier.png' },
							{ id: 'partner_greenlogistics', url: 'https://www.glogistics.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_pad,h_160,w_190/v1704402545/logo-GREEN_Logistics.jpg' },

                            // { id: 'partner_gambrinus', url: 'https://www.gambrinus.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/v1576665878/logo-gambrinus.png' },
                            // { id: 'partner_pneumatiky', url: 'https://www.pneumatiky.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/v1580399240/logo-pneumatiky.png' },
                            // { id: 'partner_rucniNaradi', url: 'https://www.rucni-naradi.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/v1580399240/logo-rucniNaradi.png' },
							{ id: 'partner_MZP', url: 'https://www.mzp.cz/', img: 'http://res.cloudinary.com/uklidmecesko/image/upload/v1426102647/logo-mzp.png' },

                        ].map((value) => (
                            <Grid item key={ value.id } component="a" target="_blank" href={ value.url }>
                                <img className={ classes.img } alt="complex" src={ value.img } />
                            </Grid>
                        )) }
                    </Grid>
                </Grid>
            </Grid>
			{ false &&
				<>
					<br />
						<Grid item key={ 'partner_mzp' } component="a" target="_blank" href={ 'https://www.mzp.cz/' }>
							<img className={ classes.img } alt="complex" src={ 'https://res.cloudinary.com/uklidmecesko/image/upload/v1426102647/logo-mzp.png' } />
						</Grid>
						<Typography component="p">Projekt byl podpořen Ministerstvem životního prostředí. Projekt nemusí vyjadřovat stanoviska MŽP.</Typography>
					<br />
				</>
			}
            <br />
			<br />
            <br />

            <Typography className={ classes.title } variant="h4" component="h1">
                { intl.formatMessage(PartnersPageMessages.pagesMediaSectionPartnersTitle) }
            </Typography>
            <br />
			<br />
            <br />
            <Grid container className={ classes.root } spacing={ 2 }>
                <Grid item xs={ 12 }>
                    <Grid container justify="center" spacing={ 5 }>
                        { [
                            { id: 'partner_rossmann', url: 'https://www.rossmann.cz', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_140/v1603665964/logo-rossmann.png' },
                            { id: 'partner_enviweb', url: 'http://www.enviweb.cz', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_140/v1421149487/enviweb_logo_ztkb2r.jpg' },
                            { id: 'partner_odpady', url: 'https://www.odpady-online.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_140/v1457907451/logo-odpady.png' },
                            { id: 'partner_protext', url: 'https://www.protext.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_140/v1426022909/logo-CTKprotext.png' },
                            { id: 'partner_inregion', url: 'http://www.inregion.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_140/v1603667874/logo-inregion.png' },
                            { id: 'partner_kazdaPlechovkaSePocita', url: 'https://everycancounts.eu/cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_140/v1611530011/logo-kazdaPlechovkaSePocita.png' },
                            { id: 'partner_upCycling.cz', url: 'https://www.upcycling.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_140/v1613082696/logo-upCycling.cz.png' },
							{ id: 'partner_reusefederace.cz', url: 'https://www.reusefederace.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_pad,h_118,w_140/v1713686267/logo-reusefederace.png' },

                        ].map((value) => (
                            <Grid item key={ value.id } component="a" target="_blank" href={ value.url }>
                                <img className={ classes.img } alt="complex" src={ value.img } />
                            </Grid>
                        )) }
                    </Grid>
                </Grid>
            </Grid>

            <br />
            <br />
            <br />
            <Typography className={ classes.title } variant="h4" component="h1">
                { intl.formatMessage(PartnersPageMessages.pagesPartnersSectionCooperatorsTitle) }
            </Typography>

            <Grid container className={ classes.root } spacing={ 2 }>
                <Grid item xs={ 12 }>
                    <Grid container justify="center" spacing={ 5 }>
                        { [
                            { id: 'partner_asekol', title: 'ASEKOL', url: 'https://www.asekol.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_110/v1582613049/logo-asekol2.png' },
                            { id: 'partner_ecobat', title: 'ECOBAT', url: 'http://www.ecobat.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_110/v1421149479/ecobat_logo_hy6tjm.jpg' },
                            { id: 'partner_ekolamp', title: 'EKOLAMP', url: 'https://www.ekolamp.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_110/v1457380634/logo-ekolamp.png' },
                            { id: 'partner_elektrowin', title: 'ELEKTROWIN', url: 'https://www.elektrowin.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_scale,w_110/v1425633297/logo-elektrowin.png' },
                            { id: 'partner_lokni', title: 'lokni', url: 'https://www.lokni.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_pad,h_93,w_130/v1725654855/logo-lokni.png' },
                            { id: 'partner_diecezni_charita', title: 'Diecézní Charita Brno', url: 'https://dchb.charita.cz/', img: 'https://res.cloudinary.com/uklidmecesko/image/upload/c_fill,w_130,h_93/v1637775615/logo-charitaBrno.png' },

                        ].map((value) => (
                            <Grid item key={ value.id } component="a" target="_blank" href={ value.url }>
                                <img className={ classes.img } alt={ value.title } title={ value.title } src={ value.img } />
                            </Grid>
                        )) }
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className={ classes.root } spacing={ 2 }  justify="center">
                <Grid item xs={12} sm={9} md={5} lg={3}>
                    <Typography variant="body1" component="div">
                        <ul>
                            { [

                                { title: 'Trash Hero', url: 'https://www.trashhero.cz/'},
                                { title: 'Kola pro Afriku o.p.s.', url: 'https://www.kolaproafriku.cz/'},
                                { title: 'POTEX', url: 'https://www.recyklujemetextil.cz/'},
                                /* { title: 'Diecézní Charita Brno', url: 'https://dchb.charita.cz/'}, */
                                { title: 'Armáda spásy', url: 'https://armadaspasy.cz/'},
                                { title: 'Potten & Pannen - Staněk group, a.s.', url: 'https://www.pottenpannen.cz/znacky/brita/'},
                                { title: 'WS CEE Communication Consulting, s.r.o', url: 'https://www.nespresso.com/cz/cs/recyklace-kavovych-kapsli'},
                                { title: 'P&G', url: 'https://www.rossmann.cz'},
                                { title: 'Zero Waste Solutions, s.r.o. ', url: 'https://www.rekrabicka.cz/'},
                                { title: 'Asociace renovátorů tonerů, z.s.', url: 'https://www.spravnytoner.cz/'},
                                { title: 'Karlovy Vary', url: 'https://mmkv.cz/'},
                                { title: 'AZS RECYKLACE ODPADU s.r.o.', url: ''},
                                { title: 'Sue Ryder', url: 'https://www.sue-ryder.cz/'},
                                { title: 'ADRA', url: 'https://www.adra.cz/pomoc-v-cr/charitativni-obchudky'},
                                { title: 'Moment', url: 'https://moment-ops.cz/daruj/'},
                                { title: 'Nadace Veronica ', url: 'https://nadace.veronica.cz/page/nadacni-obchody'},
                                { title: 'Česká federace potravinových bank', url: 'https://potravinovebanky.cz/kontakt'},
                                { title: 'Potravinová banka pro Brno a Jihomoravský kraj, z.s.', url: 'https://www.foodbankbrno.cz/'},
                                { title: 'Tranfin Oil a.s. ', url: 'https://trafinoil.cz/'},
                                { title: 'ČEKO-EKO', url: 'https://ceko-eko.cz/'},
                                { title: 'KnihoBudka', url: 'http://www.knihobudka.cz/'},
								{ title: 'Federace nábytkových bank a reuse center', url: 'https://www.reusefederace.cz/'},
                            ].map((value, key) => (
                                <li key={key}>
                                    <a href={ value.url } target="_blank" >{ value.title }</a>
                                </li>
                            )) }
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />
            <Typography className={ classes.title } variant="h4" component="h1">
                { intl.formatMessage(PartnersPageMessages.pagesPartnersSectionOpendataTitle) }
            </Typography>

            <Grid container className={ classes.root } spacing={ 2 }  justify="center">
                <Grid item xs={12} sm={9} md={5} lg={3}>
                    <Typography variant="body1" component="div">
                        <ul>
                            { [
                                { title: 'OpenStreetMap', url: 'https://openstreetmap.cz/'},
                                { title: 'SÚKL', url: 'http://www.sukl.cz/'},
                                { title: 'Ministerstvo životního prostředí', url: 'https://www.mzp.cz/'},
                                { title: 'IPR Praha', url: 'http://opendata.iprpraha.cz'},
                                { title: 'SAKO Brno', url: 'https://www.sako.cz/'},
                                { title: 'Statutární město Opava', url: 'http://kod.opava-city.cz/'},
                                { title: 'Město Ostrava', url: 'https://www.ostrava.cz/'},
                                { title: 'Město Svitavy', url: 'https://www.svitavy.cz'},
                                { title: 'Město Plzeň', url: 'https://opendata.plzen.eu/'},
                                { title: 'Město Děčín', url: 'https://opendata.mmdecin.cz/organization/mmdc'},
                                { title: 'Hradec Králové', url: 'https://www.hradeckralove.org/'},
                                { title: 'Vrchlabí', url: 'https://www.muvrchlabi.cz/'},
                                { title: 'Obec Libouchec', url: 'https://www.libouchec.cz/'},
                                { title: 'Obec Nový Bydžov', url: 'https://www.novybydzov.cz/'},

                            ].map((value, key) => (
                                <li key={key}>
                                    <a href={ value.url } target="_blank">{ value.title }</a>
                                </li>
                            )) }
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
            <br />
            <br />
            <br />

            <Typography className={ classes.title } variant="h4" component="h1">
                { intl.formatMessage(PartnersPageMessages.pagesPartnersSectionThanksTitle) }
            </Typography>
            <Grid container className={ classes.root } spacing={ 2 }  justify="center">
                <Grid item xs={12} sm={9} md={5} lg={3}>
                    <Typography variant="body1" component="div">
                        <ul>
                            { [
                                { title: 'ÁMOS VISION', url: 'https://amosvision.cz/'},
                                { title: 'Neogenia s.r.o.', url: 'https://www.neogenia.cz/'},
                                { title: 'KAPSI s.r.o.', url: 'http://www.kapsi.cz/'},
                                { title: 'EKO-KOM, a.s.', url: 'https://www.ekokom.cz/'},
                                { title: 'NevyhazujTo', url: 'https://www.nevyhazujto.cz/'},


                            ].map((value, key) => (
                                <li key={key}>
                                    <a href={ value.url } target="_blank">{ value.title }</a>
                                </li>
                            )) }
                        </ul>
                    </Typography>
                </Grid>
            </Grid>

        </Page>
    );
};

export default PartnersPage;

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginBottom: theme.spacing(3),
    },
    root: {

    },
    img: {
        margin: theme.spacing(1),
    },
}));
